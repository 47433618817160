<template>
  <v-app-bar
    color="transparent"
    prominent
    absolute
    flat
  >
    <v-container class="d-flex align-center">
      <v-toolbar-title class="font-weight-light">
        <v-icon
          class="mr-2"
          x-large
        >
          mdi-unity
        </v-icon>
        {{ title }}
      </v-toolbar-title>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: 'PagesCoreAppBar',
  data: () => ({
    titles: {
      '/login': 'Jam Board'
    }
  }),
  computed: {
    title () {
      return this.titles[this.$route.path] || 'Jam Board'
    }
  }
}
</script>
